import React, { useState } from 'react'
import Linkify from 'react-linkify'
import classNames from 'classnames'

import { regexEscape } from 'lib/utils/regexManager'
import i18n from 'lib/i18n'

import styles from '../Text.module.scss'

const RenderChild = ({
  children,
  translateProps,
  translate,
  truncate,
  size,
  highlightText,
  linkify,
  fontSize,
}: {
  children: React.ReactNode
  translateProps: Record<string, string>
  translate: boolean
  truncate: boolean
  size: number
  highlightText: string
  linkify: boolean
  fontSize: string
}): React.ReactElement => {
  let finalText = (children as string) || (children as React.ReactElement)
  const [isReadMore, setReadMore] = useState(truncate)

  const toggleReadMore = () => setReadMore((prev) => !prev)

  const buttonClass = classNames(styles.readMoreButton, {
    [styles[fontSize]]: fontSize,
  })

  const linkifyComponentDecorator = (
    href: string,
    text: string,
    key: string
  ) => (
    <a
      href={href}
      key={key}
      target="_blank"
      rel="noreferrer"
      className={styles.link}
    >
      {text}
    </a>
  )

  const highlightTextFn = (text: string, highlightText: string) => {
    if (!highlightText || typeof text !== 'string') return text

    const regex = new RegExp(`(${regexEscape(highlightText)})`, 'gi')
    const normalizedHighlight = highlightText.toLowerCase()

    const parts = text
      .split(regex)
      .map((part, index) => (
        <React.Fragment key={index}>
          {part.toLowerCase() === normalizedHighlight ? (
            <span className={styles.highlight}>{part}</span>
          ) : (
            part
          )}
        </React.Fragment>
      ))

    return <>{parts}</>
  }

  const readMoreText = (text: string, size: number) => {
    return `${text.slice(0, size)}...`
  }

  if (translate) {
    try {
      finalText = i18n?.t?.(finalText as string, translateProps)
    } catch (error) {
      return finalText as React.ReactElement
    }
  }

  if (truncate && typeof finalText === 'string' && finalText?.length > size) {
    if (isReadMore) {
      finalText = (
        <>
          {readMoreText(finalText, size)}
          {
            <button className={buttonClass} onClick={toggleReadMore}>
              Read More
            </button>
          }
        </>
      )
    } else {
      finalText = (
        <>
          {finalText}
          <button className={buttonClass} onClick={toggleReadMore}>
            Read Less
          </button>
        </>
      )
    }
  }

  if (linkify) {
    finalText = (
      <Linkify
        componentDecorator={(href: string, text: string, key: number) =>
          linkifyComponentDecorator(href, text, key.toString())
        }
      >
        {finalText}
      </Linkify>
    )
  }

  if (highlightText) {
    finalText = highlightTextFn(finalText as string, highlightText)
  }

  return finalText as React.ReactElement
}

export default RenderChild
