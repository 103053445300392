import config from 'lib/config'

export const logger = (...args: unknown[]) => {
  const isZokoAgent =
    window.globals?._logData?.email?.includes?.('@zoko.io') || false
  const timestamp = (Date.now() - (window?.globals?.timestamp || 0)) / 1000
  if (config.DEBUG_MODE || isZokoAgent) {
    window.console.log(...args, timestamp + 's')
    // window.console.trace(...args)
  }
}
